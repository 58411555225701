import { GET_ATTENDEES } from 'api/graphql/v2/queries/Attendees';
import { SearchWrapper } from 'components/calendar/calendarFiltersAndSearch/style';
import { useCallback, useRef, useState } from 'react';
import { useLazyQuery } from 'react-apollo';
import { AsyncTypeahead, AsyncTypeaheadProps } from 'react-bootstrap-typeahead';
import { alphabeticalSort } from 'utils/sort';
import Loader from '../../../views/components/ui/content/Loader';
import searchIcon from 'assets/img/bars-search-search-bar.svg';

interface OptionType {
  displayName: string;
}
interface IProps {
  clientSearch: string;
  onclientSearchChange: (value: any) => void;
  setClientList: React.Dispatch<React.SetStateAction<OptionType[]>>;
  clientList: OptionType[];
}

const ClientSearch = ({
  clientSearch,
  onclientSearchChange,
  setClientList,
  clientList
}: IProps) => {
  const AsyncTypeaheadWithOptionType = AsyncTypeahead as new (
    props: AsyncTypeaheadProps<OptionType>
  ) => AsyncTypeahead<OptionType>;

  const [clientMenuShow, setClientMenuShow] = useState(false);
  const selectClientRef = useRef(null);
  const [clientLoading, setClientLoading] = useState(false);

  const [doClientRequest, { data: clientLists }] = useLazyQuery(GET_ATTENDEES, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      const nonDisplayedAttendees = clientLists?.attendees?.sort(
        (a1: OptionType, a2: OptionType) =>
          alphabeticalSort(a1.displayName, a2.displayName)
      );
      setClientList(nonDisplayedAttendees);
      setClientLoading(false);
    }
  });

  const handleClientSearch = useCallback(query => {
    setClientLoading(true);
    if (clientSearch && query !== clientSearch) {
      onclientSearchChange([]);
    }
    doClientRequest({
      variables: {
        name: query?.trim(),
        isClient: true
      }
    });
  }, []);

  const handleClientInputChange = useCallback(input => {
    if (input.length === 0) onclientSearchChange([]);

    input.length < 3 ? setClientMenuShow(false) : setClientMenuShow(true);
    setClientLoading(true);
    setClientList([]);
  }, []);

  return (
    <>
      <SearchWrapper
        isSearchListOpen={clientMenuShow}
        view={'day'}
        isStaffPage={true}
      >
        <div
          className="client_search_wrap provider_search_wrap"
          style={{ width: '100%' }}
        >
          <AsyncTypeaheadWithOptionType
            defaultInputValue={clientSearch}
            className="client-Provider_wrapper staff_wrapper waitlist_Wrapper"
            filterBy={() => true}
            ref={selectClientRef}
            id="search-attendees"
            labelKey={'displayName'}
            isLoading={clientLoading}
            minLength={3}
            onSearch={handleClientSearch}
            options={clientList}
            placeholder={'Search Client'}
            onChange={data => {
              onclientSearchChange(data);
            }}
            delay={0}
            renderMenuItemChildren={(option: OptionType) => (
              <p className="client-Provider_option">{option.displayName}</p>
            )}
            emptyLabel={<p className="client-Provider_results">No results</p>}
            promptText={
              <p className="client-Provider_search">Type to search...</p>
            }
            searchText={
              <p className="client-Provider_loader">
                <Loader />
              </p>
            }
            onInputChange={handleClientInputChange}
            useCache={false} //to remove any cached values that may be added to calendar already
          />
          <img src={searchIcon} className="search-icon-waitList" alt="" />
        </div>
      </SearchWrapper>
    </>
  );
};
export default ClientSearch;
