import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ItemWrapper from './style';
import LocationIcon from 'views/components/icons/eventIcons/locationIcon';
import StatusIcon from 'views/components/icons/eventIcons/statusIcon';
import { EyeOutlined } from '@ant-design/icons';
import { EventType, ICalendarEvent, Status } from 'model/calendar/events';
import { Popup, Grid } from 'semantic-ui-react';
import ItemTooltip from './ItemTooltip';
import { isDisabledMovement } from 'utils/validators/dragEventValidator';
import { EVENT_TYPES } from 'utils/constants/appointmentsTypes';
import { useEventSyncStatus } from 'api/graphql/v2/hooks/eventSyncStatus';
import { FAILED_SYNC } from 'utils/constants/theme';
import {
  closeItemContextMenu,
  openItemContextMenuVar,
  removeSelectedItemToView,
  selectedItemToSubstituteVar
} from 'utils/cache/calendar';
import {
  isItemContextMenuDisabled,
  isItemSelectedToView,
  isItemSelectedForCancellation
} from 'utils/validators/BulkCancel';
import ItemContextMenu from './ItemContextMenu';
import { FEATURES, getFeatureAvailability } from 'utils/featureToggle';
import { WarningOutlined } from '@ant-design/icons';
import { useQuery, useLazyQuery } from 'react-apollo';
import {
  GET_CONVERSION_STATUS,
  GET_EVENT
} from 'api/graphql/v2/queries/Events';
import { SidebarState } from 'model/v2';
import { useReactiveVar } from '@apollo/client';

const createContextFromEvent = (e: React.MouseEvent<HTMLElement>) => {
  const left = e.clientX;
  const top = e.clientY;
  const right = left + 1;
  const bottom = top + 1;

  return {
    // returns the size of an element and its position relative to the viewport.
    getBoundingClientRect: () => ({
      left,
      top,
      right,
      bottom,
      height: 0,
      width: 0
    })
  };
};

const getEventStyle = (parentAppointmentType: any, item: ICalendarEvent) => {
  let failedStatus = false;
  let backgroundColor =
    parentAppointmentType?.backgroundColor ||
    item.appointmentType?.backgroundColor;
  let headerColor =
    parentAppointmentType?.headerColor || item.appointmentType?.headerColor;
  if (
    (item?.crStatus! > 200 || item?.athenaStatus! > 200) &&
    getFeatureAvailability(FEATURES.FAILED_SYNC)
  ) {
    headerColor = FAILED_SYNC;
    backgroundColor = 'white';
    failedStatus = true;
  }
  return { backgroundColor, headerColor, failedStatus };
};
interface Props {
  itemRenderer: any;
  openSidebar: (sidebarState: SidebarState) => void;
  setIsCancelAddAdminModalOpen: (open: boolean) => void;
}
const PlannerItem: React.FC<Props> = ({
  itemRenderer,
  openSidebar,
  setIsCancelAddAdminModalOpen
}: Props) => {
  const contextRef = React.useRef<any>();
  const [eventDuration, setEventDuration] = useState(0);
  const { getItemProps, itemContext, getResizeProps } = itemRenderer;
  const [item, setItem] = useState<ICalendarEvent>(itemRenderer.item);
  const parentAppointmentType = item.appointmentType?.parent;
  const { athenaStatus, crStatus } = useEventSyncStatus({
    eventId: item.id,
    status: item.status
  });

  useEffect(() => {
    setItem(itemRenderer.item);
  }, [itemRenderer.item]);

  let { refetch } = useQuery(GET_EVENT, {
    variables: {
      id: item.id
    },
    //TODO: handle when event is not in Dynamo throw error
    fetchPolicy: 'network-only',
    skip: !item.id || !Number.isInteger(item.id)
  });

  const [
    isConverted,
    { data: isConvertedValue, loading: isLoading }
  ] = useLazyQuery(GET_CONVERSION_STATUS, {
    variables: {
      id: item.id
    },
    //TODO: handle when event is not in Dynamo throw error
    fetchPolicy: 'network-only'
  });

  React.useEffect(() => {
    (async () => {
      let newCrStatus = item.crStatus,
        newAthenaStatus = item.athenaStatus;

      console.log('athenaCRStatusToolTip', athenaStatus, crStatus);
      if (crStatus && crStatus !== undefined && crStatus !== null)
        newCrStatus = crStatus;
      if (athenaStatus && athenaStatus !== undefined && athenaStatus !== null)
        newAthenaStatus = athenaStatus;
      if (crStatus !== undefined || athenaStatus !== undefined) {
        let eventData = await refetch({
          id: item.id
        });
        if (!eventData.data.event) return;
        let eventCRStatus =
          eventData.data !== undefined ? eventData.data.event.crStatus : '';
        let eventAthenaStatus =
          eventData.data !== undefined ? eventData.data.event.athenaStatus : '';
        if (
          newAthenaStatus == 2 &&
          eventAthenaStatus != '' &&
          newAthenaStatus != eventAthenaStatus
        ) {
          if (eventAthenaStatus > 0) {
            newAthenaStatus = eventAthenaStatus;
          }
        }
        if (
          newCrStatus == 2 &&
          eventCRStatus != '' &&
          newCrStatus != eventCRStatus
        ) {
          if (eventCRStatus > 0) {
            newCrStatus = eventCRStatus;
          }
        }
      }
      setItem(item => ({
        ...item,
        crStatus: newCrStatus,
        athenaStatus: newAthenaStatus
      }));
    })();
  }, [crStatus, athenaStatus, item.athenaStatus, item.crStatus]);
  useEffect(() => {
    if (typeof item.endTime == 'object') {
      setEventDuration(item.endTime?.diff(item.startTime, 'minutes', true));
    }
  }, [eventDuration]);

  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();

  const { backgroundColor, headerColor, failedStatus } = getEventStyle(
    parentAppointmentType,
    item
  );
  const observerBtn = useMemo(() => {
    return (
      <>
        {item.observationsCount! > 0 && (
          <Grid.Column width={3}>
            <button
              className={failedStatus ? 'observer-btn-fail' : 'observer-btn'}
            >
              <EyeOutlined className="observer-icon" /> +
              {item.observationsCount!}{' '}
              {eventDuration! > 120 && (
                <span className="observer-text">Observers</span>
              )}
            </button>
          </Grid.Column>
        )}
      </>
    );
  }, [eventDuration, item.observationsCount]);
  const isDisabled = isDisabledMovement(item);
  const canResize =
    itemContext.useResizeHandle &&
    itemContext.canResizeLeft &&
    item.appointmentType?.eventType?.name !== EVENT_TYPES.DT &&
    item.appointmentType?.eventType?.name !== EVENT_TYPES.MED &&
    !isDisabled;

  const onRightClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      isConverted();
      e.preventDefault();
      if (!getFeatureAvailability(FEATURES.BULK_CANCEL)) {
        return;
      }
      if (isItemContextMenuDisabled(item) || isItemSelectedToView(item)) {
        removeSelectedItemToView();
        closeItemContextMenu();
        return;
      }
      if (item.evalSlotLocation !== null) {
        return;
      }

      contextRef.current = createContextFromEvent(e);
      openItemContextMenuVar(item.calendarID);
    },
    [item]
  );
  const selectedItemToSubstitute = useReactiveVar(selectedItemToSubstituteVar);
  return item.type !== EventType.unAvailable ? (
    <ItemWrapper
      backgroundColor={backgroundColor}
      headerColor={headerColor}
      type={item.type}
      isDelete={item.isDelete}
      status={item.status}
      itemContext={itemContext}
      isPendingConfirmation={item?.isPendingConfirmation}
      isSelectedForCancellation={isItemSelectedForCancellation(item.id!)}
      isSelectedForSubstitute={selectedItemToSubstitute === item.id!}
    >
      <Popup
        trigger={
          <div
            {...getItemProps({
              className: 'event'
            })}
            onContextMenu={onRightClick}
          >
            {canResize && <div {...leftResizeProps}></div>}
            <div className="header" />
            <div
              className="rct-item-content"
              style={{
                height: itemContext.dimensions.height,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
            >
              {item.type == EventType.phantomObserverEvent ||
              item.type == EventType.observation ? (
                <>
                  <div className="upper">
                    <button className="phantom-observer-btn">
                      <EyeOutlined className="phantom-observer-icon" />
                    </button>
                    <div className="name">{item.title}</div>
                  </div>
                </>
              ) : item.type === EventType.clientEvent ||
                item.type === EventType.phantomClientEvent ? (
                <>
                  <Grid
                    columns={
                      failedStatus && item.observationsCount! > 0
                        ? 3
                        : !failedStatus || item.observationsCount! === 0
                        ? 2
                        : 1
                    }
                    className="remove-padding-margin"
                  >
                    <Grid.Row className="remove-padding-margin">
                      <Grid.Column
                        width={
                          failedStatus && item.observationsCount! > 0
                            ? 11
                            : failedStatus || item.observationsCount! > 0
                            ? 13
                            : 16
                        }
                        className="remove-padding-margin"
                      >
                        <div className="upper">
                          <LocationIcon
                            className="location-icon"
                            locationType={item.locationType!}
                          />

                          <span
                            className={`name ${item.isDelete ? 'deleted' : ''}`}
                          >
                            {item.title}
                          </span>
                        </div>
                        <div className="lower">
                          <StatusIcon
                            className="location-icon"
                            status={item.status || Status.scheduled}
                            isPendingConfirmation={item?.isPendingConfirmation}
                            recurringEvery={
                              item.recurrencePattern?.recurringEvery
                            }
                            isDelete={item.isDelete}
                          />
                          <span
                            className={`superType ${
                              item.isDelete ? 'deleted' : ''
                            }`}
                          >
                            {parentAppointmentType?.title ||
                              item?.appointmentType?.title}
                          </span>
                        </div>
                      </Grid.Column>
                      {observerBtn}
                      {failedStatus && (
                        <Grid.Column
                          width={eventDuration! > 60 ? 1 : 2}
                          floated="right"
                        >
                          <WarningOutlined className="failed-icon" />
                        </Grid.Column>
                      )}
                    </Grid.Row>
                  </Grid>
                </>
              ) : (
                <Grid
                  columns={failedStatus ? 2 : 1}
                  className="remove-padding-margin"
                >
                  <Grid.Row className="remove-padding-margin">
                    {failedStatus && eventDuration! <= 30 ? (
                      <></>
                    ) : (
                      <Grid.Column
                        className="item-event"
                        width={failedStatus ? 11 : 16}
                      >
                        {item.isDelete && (
                          <StatusIcon
                            className="location-icon"
                            status={item.status || Status.scheduled}
                            isPendingConfirmation={item?.isPendingConfirmation}
                            recurringEvery={
                              item.recurrencePattern?.recurringEvery
                            }
                            isDelete={item.isDelete}
                          />
                        )}
                        <div
                          className={`name ${item.isDelete ? 'deleted' : ''}`}
                        >
                          {item?.appointmentType?.title}
                        </div>
                      </Grid.Column>
                    )}
                    {failedStatus && (
                      <Grid.Column width={3}>
                        <WarningOutlined className="admin-failed-icon" />
                      </Grid.Column>
                    )}
                  </Grid.Row>
                </Grid>
              )}
            </div>
            {canResize && <div {...rightResizeProps}></div>}
          </div>
        }
        content={<ItemTooltip itemRenderer={itemRenderer} />}
        position="bottom left"
      />

      <ItemContextMenu
        item={item}
        itemRef={contextRef}
        isConverted={isConvertedValue}
        isLoading={isLoading}
        openSidebar={openSidebar}
        setIsCancelAddAdminModalOpen={setIsCancelAddAdminModalOpen}
      />
    </ItemWrapper>
  ) : (
    <ItemWrapper
      backgroundColor={backgroundColor}
      headerColor={headerColor}
      type={item.type}
      itemContext={itemContext}
    >
      <div
        {...getItemProps({
          className: EventType.unAvailable
        })}
      ></div>
    </ItemWrapper>
  );
};
export default React.memo(PlannerItem);
