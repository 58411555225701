import React, { useState, memo } from 'react';
import { Select } from 'lib/ui';

const WaitListSelect = ({ value, options, ...restProps }) => {
  const [open, setOpen] = useState(false);

  const handleDropdownVisibleChange = visible => {
    setOpen(visible);
  };
  return (
    <Select
      value={value}
      onDropdownVisibleChange={handleDropdownVisibleChange}
      open={open}
      dropdownClassName="custom-dropdown"
      options={options}
      {...restProps}
    />
  );
};

export default memo(WaitListSelect);
