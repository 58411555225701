import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  TitleWrapper,
  WaitListTitleWrapper,
  WaitlistWrapper,
  WaitListFilterWrapper,
  WaitListPopOver
} from './waitlist.styled';
import searchIcon from 'assets/img/bars-search-search-bar.svg';
import SearchForm from 'views/components/ui/form/search/index';
import Content from 'views/components/ui/content';
import Table from 'views/containers/table';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { formatClinicName } from 'utils/format';
import { DEFAULT_PAGE_SIZE, DEFAULT_SORT } from 'utils/constants/default';
import { EditProviderButton } from 'components/icons/EditProviderButton';
import { CheckListWrapper } from 'components/calendar/filters/style';
import { Input } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ClinicFilter from 'components/calendar/filters/clinicFilter/index';
import DepartmentFilter from 'components/calendar/filters/departmentFilter/index';
import ActiveStatusFilter from 'components/calendar/filters/activeStatusFilter/index';
import Loader from '../ui/content/Loader';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import FilterTagList from 'components/calendar/calendarToolbar/FilterTagList';
import { CALENDAR_FILTER_ENUM } from 'utils/constants/calendarFilters';
import { GET_WAITLIST } from 'api/graphql/queries/careplan';
import {
  mapCarePlanToTable,
  table_Skeletal_Loader
} from 'utils/mappers/carePlans';
import { CURRENT_WAITLIST_COLUMNS } from 'utils/constants/tables/waitlist';
import { waitListFilterHelper } from 'helpers/filterHekper';
import FilterListFilled from 'assets/img/FilterListFilled.png';
import styled from 'styled-components';
import Expand from 'assets/img/Expand.png';
import WaitListSideDrawer from '../sideDrawer/waitListSideDrawer';
import { mapWaitListClientAvailabilityFilters } from 'helpers/clientAvailabilityHelper';
import PopOverComp from 'components/popover';
import SideMenuBar from 'components/sideMenuBar';
import SideBar from 'components/sidebar/sidebar';
import { Skeleton } from 'antd';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import {
  CalendarFiltersAndSearchWrapper,
  SearchWrapper
} from 'components/calendar/calendarFiltersAndSearch/style';
import { GET_ATTENDEES } from 'api/graphql/v2/queries/Attendees';
import {
  searchedClientsPrevVar,
  defaultFilterPrefVar
} from 'utils/cache/filters';
import { useReactiveVar } from '@apollo/client';
import { alphabeticalSort } from 'utils/sort';
import { FilterPageNameEnum } from 'components/calendar/calendarToolbar/FilterTagList/form/utils';
import useCalendarFilterFetcherHook from 'hooks/calendarFilterFetcherHook';
import { mapToLocationsDownOption } from 'utils/mappers/form';
import { GET_WAITLIST_INITIAL_LOAD_DATA } from 'api/graphql/queries/careplan';
import InfiniteScroll from 'react-infinite-scroll-component';
import { mapWaitListSSStatus } from 'helpers/clientAvailabilityHelper';
import {
  useUpdateWaitList,
  useSessionOptions,
  useCloseSelectDropDown
} from 'hooks/waitListHook';
import FilterLayout from './components/filters/FilterLayout';
import { checkIfFilterSelected } from 'utils/mappers/carePlans';
import moment from 'moment';
import { savedFilterNameEnum } from 'components/calendar/calendarToolbar/FilterTagList/form/utils';
import { waitListPageCount } from 'utils/cache/calendar';
export const waitlistSavedFilterContext = React.createContext(undefined);

const WaitlistPage = () => {
  const location = useLocation();
  //State for provider search
  const [list, setList] = useState([]);

  //States for SideBar's
  const [showSidebar, setShowSidebar] = useState(false);
  const [sidebarData, setSidebarData] = useState();
  const [isShowDrawer, setIsShowDrawer] = useState(false);
  const [menuShown, setMenuShown] = useState(false);
  const [activeTab, setActiveTab] = useState('notes');
  const [editFilterModal, setEditFilterModal] = useState(false);

  const [locations, setLocations] = useState();

  //Saved Filters
  const [hasSavedFilters, setHasSavedFilters] = useState(true);
  const [selectionTypeData, setSelectionTypeData] = useState();
  const [carePlanFiltersData, setCarePlanFiltersData] = useState();
  const [checkedSavedFilter, setCheckedSavedFilter] = useState('');
  const [checkedFilter, setCheckedFilter] = useState('');
  const [clickedFilter, setClickedFilter] = useState('');

  //Additional Filters
  const [additionalFilter, setAdditionalFilter] = useState({
    isFilterApplied: false,
    locations: [],
    startTime: null,
    endTime: null,
    days: []
  });

  //States for handling filters
  const [checkedClinics, setCheckedClinics] = useState([]);
  const [checkedStatus, setCheckedStatus] = useState([]);
  const [checkedDepartments, setCheckedDepartments] = useState([]);
  const [checkedWaitListReasons, setWaitListReasons] = useState([]);
  const [checkedServiceType, setServiceType] = useState([]);
  const [checkedTherapyType, setTherapyType] = useState([]);
  const [checkedClientAvailability, setClientAvailability] = useState([]);
  const [checkedSmartSchedulingStatus, setSmartSchedulingStatus] = useState([]);

  //state to handle table data with inifinity scrolls
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [clientLoading, setIsLoading] = useState(false);
  const searchedClients = useReactiveVar(searchedClientsPrevVar);

  //Request option creation
  let requestedOptions = useSessionOptions(10);
  const [clientName, setClientName] = useState('');
  const waitListCount = useReactiveVar(waitListPageCount);

  //Get Initial load data for filter option saved filter and default filter
  const [getWaitListInitialLoadData, { loading: isLoading }] = useLazyQuery(
    GET_WAITLIST_INITIAL_LOAD_DATA,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: data => {
        let {
          waitListClientLocations,
          filterSelections,
          filterList
        } = data.getWaitListIntialLoadData;
        setLocations({ clientLocations: waitListClientLocations });
        setSelectionTypeData(filterSelections);
        setCarePlanFiltersData({ WaitListFilter: filterList });
      },
      onError: error => {
        console.log(error, 'Error while getting location');
      }
    }
  );

  //Method to close the dropdown on Table horizontal scroll
  useCloseSelectDropDown('.infinite-scroll-component');

  useEffect(() => {
    setCheckedFilter(checkedSavedFilter);
  }, [checkedSavedFilter]);

  //Url Search Params
  const searchParams = useMemo(() => new URLSearchParams(location?.search), [
    location
  ]);

  //Method to clear additional filter
  const clearAdditionalFilter = useCallback(() => {
    setCheckedFilter('');
    setAdditionalFilter({
      isFilterApplied: false,
      locations: [],
      startTime: null,
      endTime: null,
      days: []
    });
  });
  //Get Default filter to load on UI
  const useDefaultFilter = useReactiveVar(defaultFilterPrefVar);

  const locationsOptions = useMemo(() => {
    return mapToLocationsDownOption(locations?.clientLocations || []);
  }, [locations?.clientLocations]);

  const {
    defaultFilter,
    savedFilters,
    allFilters,
    savedFiltersMap,
    isSavedFilterLoading,
    useFilterRemovalHook,
    SavedFilter,
    refetch
  } = useCalendarFilterFetcherHook(FilterPageNameEnum.waitList, 'no-cache');

  //Method to remove filters
  const handleCarePlanRemoveFilter = useFilterRemovalHook(
    setCheckedSavedFilter,
    CALENDAR_FILTER_ENUM,
    {
      setCheckedClinics,
      setWaitListReasons,
      setTherapyType,
      setServiceType,
      setClientAvailability,
      setCheckedStatus,
      setSmartSchedulingStatus
    }
  );

  const filterSelectionType = {};
  useMemo(() => {
    selectionTypeData?.map(selectionType => {
      filterSelectionType[selectionType.typeName] = selectionType.id;
    });
  }, [selectionTypeData, filterSelectionType]);

  //Add Saved filters
  const handleSavedFilter = useCallback(
    name => {
      const savedFilter = savedFiltersMap.get(name);
      if (!savedFilter) return;
      SavedFilter(
        FilterPageNameEnum.waitList,
        {
          setCheckedStatus,
          setWaitListReasons,
          setCheckedClinics,
          setTherapyType,
          setServiceType,
          setClientAvailability,
          setSmartSchedulingStatus
        },
        setAdditionalFilter,
        setCheckedSavedFilter,
        savedFilterNameEnum.saved,
        savedFilter,
        name
      );
    },
    [savedFiltersMap]
  );

  useEffect(() => {
    if (defaultFilter && checkedSavedFilter === '') {
      console.log('Inside saved filter');
      // setCheckedLocations(defaultFilter?.locations);

      SavedFilter(
        FilterPageNameEnum.waitList,
        {
          setCheckedStatus,
          setWaitListReasons,
          setCheckedClinics,
          setTherapyType,
          setServiceType,
          setClientAvailability,
          setSmartSchedulingStatus
        },
        setAdditionalFilter,
        setCheckedSavedFilter,
        savedFilterNameEnum.default,
        defaultFilter,
        false,
        defaultFilterPrefVar,
        FilterPageNameEnum.waitList
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilter, useDefaultFilter]);

  useEffect(() => {
    if (checkedFilter && checkedFilter !== '') handleSavedFilter(checkedFilter);
  }, [savedFilters]);

  //Client search logic
  const handleSearchedValues = useCallback(
    list => {
      return list;
    },
    [list]
  );

  const onSetClickedFilter = useCallback(
    label => {
      setClickedFilter(label);
      setEditFilterModal(true);
    },
    [setClickedFilter, setEditFilterModal]
  );

  //Modal close for additional filter
  const onCloseModal = useCallback(() => {
    setEditFilterModal(false);
  }, [setEditFilterModal]);

  //Clear all loaded filters
  const clearFilter = useCallback(
    clearFilter => {
      setCheckedClinics(prev => (prev.length ? [] : prev));
      setServiceType(prev => (prev.length ? [] : prev));
      setTherapyType(prev => (prev.length ? [] : prev));
      setClientAvailability(prev => (prev.length ? [] : prev));
      setSmartSchedulingStatus(prev => (prev.length ? [] : prev));
      setWaitListReasons(prev => (prev.length ? [] : prev));
      setCheckedStatus(prev => (prev.length ? [] : prev));
    },
    [
      setCheckedClinics,
      setServiceType,
      setTherapyType,
      setClientAvailability,
      setSmartSchedulingStatus,
      setWaitListReasons,
      setCheckedStatus
    ]
  );

  //Clear all filters
  const clearAllSelectedFilters = useCallback(() => {
    setCheckedFilter('');
    clearFilter();
  }, []);

  const handleNoteAdded = (clientId, addedNote) => {
    // Update the table data with the new note
    setTableData(prevTableData => {
      return prevTableData.map(item => {
        if (item.clientId === clientId) {
          return {
            ...item,
            notes: [...item.notes, addedNote],
            notesCount: item.notesCount + 1
          };
        }
        return item;
      });
    });
  };

  const selectClientRef = useRef(null);

  //Query to get the waitlist Item
  const {
    loading: waitListDataLoading,
    error: waitListDataError,
    data: waitListData
  } = useQuery(GET_WAITLIST, {
    fetchPolicy: 'network-only',
    variables: {
      waitListFilter: {
        clinicName: checkedClinics.map(data => data.label),
        waitListReason: checkedWaitListReasons.map(data => data?.label),
        therapyType: checkedTherapyType.map(data => data?.label),
        serviceType: checkedServiceType.map(data => data?.label),
        status: checkedStatus.map(data => data?.label),
        SmartScheduling_status: mapWaitListSSStatus(
          checkedSmartSchedulingStatus.map(data => data?.label)
        )
      },
      clientAvailability: mapWaitListClientAvailabilityFilters(
        checkedClientAvailability,
        additionalFilter,
        locations
      ),
      limit: 25,
      page: page,
      name: clientName
    },
    onCompleted: data => {
      const newPatients = mapCarePlanToTable(data);
      waitListPageCount(data.getCarePlansForWaitList.Count);
      setTableData(prevPatients => {
        return page > 1 ? [...prevPatients, ...newPatients] : newPatients;
      });
    },
    onError: error => {
      console.error('Error querying waitlist: ', error);
    }
  });

  const hasMore = waitListData
    ? waitListData.getCarePlansForWaitList.currentPageLength >= 25
    : true;
  //Infinity scroll logic to get next set of results
  const fetchMoreData = () => {
    if (waitListDataLoading) {
      return;
    } else {
      setPage(prevPage => prevPage + 1);
    }
  };

  useEffect(() => {
    setPage(1);
    setTableData([]);
  }, [
    checkedClinics,
    checkedWaitListReasons,
    checkedTherapyType,
    checkedServiceType,
    checkedStatus,
    checkedClientAvailability,
    clientName,
    additionalFilter.isFilterApplied
  ]);

  //Table total count
  const tableCount = waitListData ? waitListData.Count : 0;

  //Skelatal loader for table
  const loaderView = table_Skeletal_Loader(Skeleton, false, tableData);

  useEffect(() => {
    getWaitListInitialLoadData();
  }, []);

  //Edit waitlist fields i.e Reason status and Requested
  const {
    handleWaitlistReasonChange,
    handleCPStatusChange
  } = useUpdateWaitList();

  const onFieldsEdited = async (
    cpName,
    carePlanId,
    selectedWaitListReason,
    requestedValue,
    updatedBookingDetails
  ) => {
    const reasonsArr = checkedWaitListReasons.map(reasons => reasons.value);
    let shouldUpdateCount = await handleWaitlistReasonChange(
      selectedWaitListReason,
      carePlanId,
      requestedValue,
      updatedBookingDetails,
      setTableData,
      setSidebarData,
      reasonsArr
    );
    shouldUpdateCount && waitListPageCount(waitListCount - 1);
  };

  const handleWaitListUpdate = useCallback(
    async (value, record, updatedField, requested) => {
      if (updatedField === 'waitlistReason') {
        const reasonsArr = checkedWaitListReasons.map(reasons => reasons.value);
        let shouldUpdateCount = await handleWaitlistReasonChange(
          value,
          record.carePlanId,
          requested,
          record.bookingDetails,
          setTableData,
          setSidebarData,
          reasonsArr
        );
        shouldUpdateCount && waitListPageCount(waitListCount - 1);
      } else if (updatedField === 'cpStatus') {
        const checkedStatusArr = checkedStatus.map(status => status.value);
        let shouldUpdateCount = handleCPStatusChange(
          value,
          record.carePlanId,
          setTableData,
          checkedStatusArr,
          waitListPageCount
        );
        shouldUpdateCount && waitListPageCount(waitListCount - 1);
      }
    }
  );
  //

  //Variable to batch all the selected filter result
  const carePlanFilter = {
    clinics: checkedClinics,
    departments: [],
    locations: [],
    status: checkedStatus,
    savedFilterName: '',
    waitListReason: checkedWaitListReasons,
    therapyType: checkedTherapyType,
    serviceType: checkedServiceType,
    clientAvailability: checkedClientAvailability,
    smartSchedulingStatus: checkedSmartSchedulingStatus
  };

  const waitListMappedFilters = useCallback(
    type => {
      if (carePlanFiltersData) {
        return waitListFilterHelper(
          type,
          carePlanFiltersData?.WaitListFilter?.WaitListFilter
        );
      }
    },
    [carePlanFiltersData]
  );

  const [doRequest, { data: attendeesList }] = useLazyQuery(GET_ATTENDEES, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      const nonDisplayedAttendees = attendeesList?.attendees?.sort((a1, a2) =>
        alphabeticalSort(a1.displayName, a2.displayName)
      );
      setList(nonDisplayedAttendees);
      setIsLoading(false);
    }
  });

  const handleAttendeeChange = useCallback(data => {
    selectClientRef?.current?.clear();
    //if input is cleared
  }, []);

  const handleClientSearch = useCallback(
    query => {
      setIsLoading(true);
      if (clientName && query !== clientName) {
        onSearchChange([]);
      }
      if (query?.length > 0) {
        doRequest({
          variables: {
            name: query?.trim(),
            isClient: true
          }
        });
      }
    },
    [doRequest]
  );

  const handleInputChange = useCallback(input => {
    if (input.length === 0) onSearchChange([]);
    input.length < 3 ? setMenuShown(false) : setMenuShown(true);
    setIsLoading(true);
    setList([]);
  }, []);

  //Get the waitlist for searched clients
  const onSearchChange = e => {
    let [value] = e;
    if (!value?.name) {
      setClientName('');
    } else if (value) {
      setClientName(value.name);
    }
  };

  const onSearch = value => {
    if (value) {
      setClientName(value);
    }
  };

  return (
    <div style={{ display: 'flex', minHeight: '100vh' }}>
      <SideMenuBar waitListCount={waitListCount} />
      <div style={{ width: '100vw', overflowX: 'scroll' }}>
        <WaitListTitleWrapper>
          <div className="inner-wrap-waitList">
            <h1>Waitlisted Patients</h1>
            <CalendarFiltersAndSearchWrapper>
              <SearchWrapper isSearchListOpen={menuShown} view={'day'}>
                <div className="client_search_wrap">
                  <AsyncTypeahead
                    defaultInputValue={clientName}
                    selected={''}
                    className="client-Provider_wrapper waitlist_Wrapper"
                    filterBy={() => true}
                    ref={selectClientRef}
                    id="search-attendees"
                    labelKey="displayName"
                    isLoading={clientLoading}
                    minLength={3}
                    onSearch={handleClientSearch}
                    options={list}
                    placeholder={"Search a Patient's name"}
                    onChange={data => {
                      onSearchChange(data);
                    }}
                    delay={0}
                    renderMenuItemChildren={option => (
                      <p className="client-Provider_option">
                        {option.displayName}
                      </p>
                    )}
                    emptyLabel={
                      <p className="client-Provider_results">No results</p>
                    }
                    promptText={
                      <p className="client-Provider_search">
                        Type to search...
                      </p>
                    }
                    searchText={
                      <p className="client-Provider_loader">
                        <Loader />
                      </p>
                    }
                    onInputChange={handleInputChange}
                    useCache={false} //to remove any cached values that may be added to calendar already
                  />
                  <img
                    src={searchIcon}
                    className="search-icon-waitList"
                    alt=""
                  />
                </div>
              </SearchWrapper>
            </CalendarFiltersAndSearchWrapper>
          </div>
        </WaitListTitleWrapper>
        <waitlistSavedFilterContext.Provider value={setCheckedSavedFilter}>
          <WaitListFilterWrapper>
            <div className="actions">
              <FilterLayout
                isLoading={isLoading}
                carePlanFiltersData={carePlanFiltersData}
                checkedClinics={checkedClinics}
                setCheckedClinics={setCheckedClinics}
                checkedServiceType={checkedServiceType}
                setServiceType={setServiceType}
                checkedTherapyType={checkedTherapyType}
                setTherapyType={setTherapyType}
                checkedStatus={checkedStatus}
                setCheckedStatus={setCheckedStatus}
                checkedSmartSchedulingStatus={checkedSmartSchedulingStatus}
                setSmartSchedulingStatus={setSmartSchedulingStatus}
                checkedWaitListReasons={checkedWaitListReasons}
                setWaitListReasons={setWaitListReasons}
                checkedClientAvailability={checkedClientAvailability}
                setClientAvailability={setClientAvailability}
                setIsShowDrawer={setIsShowDrawer}
              />
            </div>
          </WaitListFilterWrapper>
          <WaitlistWrapper>
            <div className="inner-wrap staff-filters">
              <FilterTagList
                refetch={refetch}
                filters={carePlanFilter}
                pageName={FilterPageNameEnum.waitList}
                onRemoveFilter={handleCarePlanRemoveFilter}
                showSavedIcon={true}
                additionalFilter={additionalFilter}
                clearAdditionalFilter={clearAdditionalFilter}
                checkedFilter={checkedFilter}
                setCheckedSavedFilter={setCheckedSavedFilter}
                clearAllSelectedFilters={clearAllSelectedFilters}
                showClearFilters={true}
              />
            </div>
            <div className="inner-wrap">
              <InfiniteScroll
                dataLength={tableData.length}
                next={fetchMoreData}
                hasMore={hasMore}
                className="infinite-scroll-component"
                endMessage={
                  <p style={{ textAlign: 'center' }}>No more patients</p>
                }
              >
                <Table
                  columns={CURRENT_WAITLIST_COLUMNS(
                    carePlanFiltersData?.WaitListFilter?.waitListReasons,
                    PopOverComp,
                    WaitListPopOver,
                    setShowSidebar,
                    setSidebarData,
                    waitListDataLoading,
                    handleNoteAdded,
                    setActiveTab,
                    showSidebar,
                    handleWaitListUpdate,
                    requestedOptions
                  )}
                  dataSource={waitListDataLoading ? loaderView : tableData}
                  pagination={false}
                  rowKey="id"
                  onRow={row => ({
                    onClick: () => {}
                  })}
                  clearAllSelectedFilters={clearAllSelectedFilters}
                  // scroll={{ x: 2000 }}
                  style={{ minWidth: '2100px' }}
                  rowClassName={() => {
                    return 'custom-waitlist-row';
                  }}
                />
              </InfiniteScroll>
              {showSidebar ? (
                <SideBar
                  show={showSidebar}
                  close={() => setShowSidebar(!showSidebar)}
                  sidebarData={sidebarData}
                  setSidebarData={setSidebarData}
                  onNoteAdded={handleNoteAdded}
                  waitListReasons={
                    carePlanFiltersData?.WaitListFilter?.waitListReasons
                  }
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  onFieldsEdited={onFieldsEdited}
                />
              ) : null}
            </div>

            <WaitListSideDrawer
              refetch={refetch}
              key={isShowDrawer}
              setAdditionalFilter={setAdditionalFilter}
              waitListDataLoading={isLoading}
              Skeleton={Skeleton}
              additionalFilter={additionalFilter}
              locations={locations?.clientLocations}
              isShowDrawer={isShowDrawer}
              location={location}
              setIsShowDrawer={setIsShowDrawer}
              searchParams={searchParams}
              selectSavedFilter={handleSavedFilter}
              savedFilter={checkedSavedFilter}
              handleSearchedValues={handleSearchedValues}
              setHasData={setHasSavedFilters}
              setClickedFilter={onSetClickedFilter}
              savedFilters={savedFilters}
              defaultFilter={defaultFilter}
              isSavedFilterLoading={isSavedFilterLoading && isLoading}
              pageName={FilterPageNameEnum.waitList}
              setCheckedFilter={setCheckedFilter}
              checkedFilter={checkedFilter}
              clearAllFilters={clearFilter}
              filterSelectionType={filterSelectionType}
              allFilters={allFilters}
            />
          </WaitlistWrapper>
        </waitlistSavedFilterContext.Provider>
      </div>
    </div>
  );
};
export default WaitlistPage;
