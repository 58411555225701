import React, { ChangeEvent, LegacyRef } from 'react';
import { Input } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
// import Loader from '../ui/content/Loader';

import ClinicFilter from 'components/calendar/filters/clinicFilter/index';
import {
  ICalendarFilterOption,
  IClinicFilterData
} from 'model/calendar/filters';

export const searchValueContext = React.createContext('');
interface Props {
  clinicFiltersRef: LegacyRef<HTMLDivElement> | undefined;
  handleGenericBlur: (
    e: React.FocusEvent<HTMLDivElement>,
    filterType: string
  ) => void;
  toggleFilterList: (type: string) => void;
  filterDropdownExpand: Record<string, Boolean>;
  handleGenericSearch: (
    _e: ChangeEvent<HTMLInputElement>,
    data: { value: string | number },
    typeOfSearch: string
  ) => void;
  searchValue: Record<string, string>;
  hasClinics: boolean;
  isLoading: boolean | undefined;
  FilterData: IClinicFilterData[];
  handleClinicSearchedValues: (
    list: ICalendarFilterOption[]
  ) => ICalendarFilterOption[];
  checkedClinics: ICalendarFilterOption[];
  setCheckedClinics: React.Dispatch<
    React.SetStateAction<ICalendarFilterOption[]>
  >;
  setHasClinics: React.Dispatch<React.SetStateAction<boolean>>;
  clearFilter: (clearFilter: any) => void;
  Loader: React.FC;
}
const WaitlistClinicFilter = ({
  clinicFiltersRef,
  handleGenericBlur,
  toggleFilterList,
  filterDropdownExpand,
  handleGenericSearch,
  searchValue,
  hasClinics,
  isLoading,
  FilterData,
  handleClinicSearchedValues,
  checkedClinics,
  setCheckedClinics,
  setHasClinics,
  clearFilter,
  Loader
}: Props) => {
  return (
    <div
      ref={clinicFiltersRef}
      onBlur={e => handleGenericBlur(e, 'clinic')}
      tabIndex={-1}
    >
      <div
        className="filter-input_wrapper waitList_input_wrapper"
        onClick={() => toggleFilterList('clinic')}
      >
        <Input
          className="filter_input_waitlist"
          placeholder={filterDropdownExpand.clinic ? 'Search' : 'Clinic Name'}
          onChange={event =>
            handleGenericSearch(
              event,
              { value: event.currentTarget.value },
              'clinic'
            )
          }
        />
        {!filterDropdownExpand.clinic && (
          <FontAwesomeIcon className="icon" icon={faCaretDown} />
        )}
        {filterDropdownExpand.clinic && (
          <FontAwesomeIcon
            className="icon"
            icon={faCaretDown}
            style={{ opacity: 0 }}
          />
        )}
      </div>
      {filterDropdownExpand.clinic && (
        <>
          <searchValueContext.Provider value={searchValue.clinic}>
            <div
              className="list_wrapper"
              style={{
                position: 'absolute',
                top: '100%',
                left: 0,
                zIndex: 1,
                width: '100%'
              }}
            >
              <div className="scrollable_list">
                {!hasClinics && (
                  <p className="no-results_filter">No results found</p>
                )}
                {isLoading && <Loader />}
                <ClinicFilter
                  clinicsList={FilterData || []}
                  handleSearchedValues={handleClinicSearchedValues}
                  checkedClinics={checkedClinics}
                  setCheckedClinics={setCheckedClinics}
                  setHasData={setHasClinics}
                />
              </div>
              <div className="calender-filters_actions">
                <span onClick={() => clearFilter(setCheckedClinics)}>
                  Clear all
                </span>
              </div>
            </div>
          </searchValueContext.Provider>
        </>
      )}
    </div>
  );
};

export default WaitlistClinicFilter;
