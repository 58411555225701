import gql from 'graphql-tag';

export const GET_ATTENDEES = gql`
  query($name: String!, $isClient: Boolean!, $isStaffPage: Boolean) {
    attendees(name: $name, isClient: $isClient, isStaffPage: $isStaffPage) {
      id
      corticaPrograms {
        id
        programName
      }
      displayName
      attendeeType
      name
      status
      speciality {
        id
        color
        abbreviation
        department
      }
      clinic {
        id
        abbreviation
      }
      appointmentTypes {
        id
        title
        eventType {
          id
          name
        }
      }
      email
      profile {
        dob
      }
      telehealthLinks
    }
  }
`;
