import styled from 'styled-components';
import { RED } from 'utils/constants/theme';

export const TitleWrapper = styled.div`
  min-height: 96px;
  background-color: #ffffff;
  border-bottom: 1px solid #e8ecef;
  position: relative;
  h1 {
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 0.38px;
  }
  .inner-wrap {
    box-sizing: border-box;
    padding: 16px 16px;
    position: relative;
    width: 100%;
    margin: 0 auto;
    max-width: 1280px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:after {
      display: none;
    }
  }
  .actions {
    display: flex;
    align-items: center;
    > * {
      margin-left: 15px;
    }
  }
`;
export const ContentWrapper = styled.div`
  overflow: hidden;
  .inner-wrap {
    position: relative;
    width: 100%;
    min-height: calc(100vh - 160px);
    margin: 0 auto;
    max-width: 1280px;
    padding: 0;
    .ant-table-content {
      &:after {
        content: none;
      }
    }
    .ant-table-thead {
      th {
        border: none;
        padding: 10px 27px;
        color: #919699;
      }
    }
    &.table-scroll {
      height: 82vh;
      overflow-x: inherit;
      .loading-container {
        position: relative;
        bottom: 0;
        height: 20px;
      }
    }
  }
`;
export const AddFormWrapper = styled.div`
  .form-title {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: 0.26px;
    color: #21303a;
    padding-bottom: 16px;
  }
  .optionalSpan {
    font-size: 12px;
    opacity: 0.3;
  }
  .address_cta {
    background: transparent;
    border: 2px solid #6f4bf1;
    border-radius: 8px;
    height: 32px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    color: #6f4bf1;
    padding: 8px 24px 7px 25px;
    cursor: pointer;
    outline: none;
    margin-bottom: 20px;
    &:active {
      outline: none;
    }
  }
  .address_cta_wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    button {
      color: #cc3f3f;
      background: transparent;
      cursor: pointer;
      outline: none;
      border: none;
      text-decoration: underline;
      &:active {
        border: none;
        outline: none;
      }
    }
    svg {
      cursor: pointer;
    }
  }
  .error {
    color: ${RED};
  }
  .ant-checkbox + span {
    padding-right: 0;
  }
`;
