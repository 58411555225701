import moment from 'moment';
import { customizeRange } from 'utils/builders/calendar/';
import { CLINIC_TYPES } from 'utils/constants/lists';

// TODO: handle arguments with empty/undefined values

/* Format username: Last, First */
export const formatUsername = (first, last) => last + ', ' + first;

/* Format username: First Last */
export const formatUsernameOriginal = (first, last) => {
  return `${first || ''} ${last || ''}`;
};

export const formatAttendeeName = (first, last, abbreviation, speciality) => {
  const nameText = formatUsernameOriginal(first, last);
  const specText = speciality ? `, ${speciality}` : '';
  const abbText = abbreviation ? ` (${abbreviation})` : '';
  return nameText + specText + abbText;
};

export const formatAttendeeNameV2 = (
  name,
  abbreviation,
  speciality,
  displayName
) => {
  const nameText = name ? `${name}` : '';
  const specText = speciality ? `, ${speciality}` : '';
  const abbText = abbreviation ? ` (${abbreviation})` : '';
  return nameText + specText + abbText;
};

/* Parse a formatted name as "Last, First" into "First Last" */
export const parseUsername = name =>
  name
    ?.split(',')
    .map(x => x.trim())
    .reverse()
    .join(' ');

/* Parse a formatted name as "First, Last" into "First Last" */
export const parseDisplayName = name => name?.replace(',', '');

/* Format text initial: female -> F */
export const formatInitial = text => text?.charAt(0).toUpperCase() || '';

/* Format age display: {*}y{*}mo */
export const formatAge = age => age.years + 'y' + age.months + 'mo';

/* Convert date from ISO format: yyyy-mm-ddThh:mm:ss.sssZ => mm/dd/yyyy */
export const convertDateFromISO = date => moment.utc(date).format('MM/DD/YYYY');

/* Convert date to ISO format: mm/dd/yyyy => yyyy-mm-ddThh:mm:ss.sssZ */
export const convertDateToISO = date => moment.utc(date).toISOString();

/* Convert date from moment object to a MM/DD/YYYY-formatted string */
export const convertMomentToString = date => date?.format('MM/DD/YYYY');

export const convertToDate = date => date?.format('YYYY-MM-DD');
/* Convert date from ISO format to a moment object */
export const convertDateToMoment = date =>
  date ? moment.utc(date) : undefined;

/* Format clinic name display: Clinic Name (CN) */
export const formatClinicName = clinic =>
  `${clinic?.name || clinic?.clinicName} (${clinic?.abbreviation ||
    clinic?.clinicAbbreviation})`;

/* Format time display: 24-hour clock =>  12-hour clock */
export const formatTime = time => moment(time, 'HH:mm').format('h:mm a');

export const formatEventPopupTime = time => moment(time).format('h:mm a');

export const formatMinToTime = time =>
  moment()
    .hours(time / 60)
    .minutes(time % 60)
    .format('hh:mm a');

export const formatHhMmTime = time => moment(time).format('h:mm');

export const formatMinToHHmmTime = time =>
  moment()
    .hours(time / 60)
    .minutes(time % 60)
    .format('HH:mm');

export const dayMoment = date => moment(date).format('dddd DD MMM, YYYY');

export const calendarDayMoment = date =>
  moment(date).format('dddd MMM DD, YYYY');

export const MonthDayYearMoment = date => moment(date).format('MMM DD, YYYY');
export const MonthDayYearMomentForEval = date =>
  moment(date).format('MMM DD, YYYY');

export const calendarWeekMoment = selectedDate => {
  const range = customizeRange(selectedDate, 'week');
  let date = moment(range.start).format('MMM DD') + '-';
  const startMonth = moment(date).format('MMM');
  const endMonth = moment(range.end).format('MMM');
  date =
    startMonth === endMonth
      ? date + moment(range.end).format('DD')
      : date + moment(range.end).format('MMM DD');

  date = date + ',' + moment(range.end).format('YYYY');
  return date;
};

export const dayNumber = date => moment(date).format('DD');
export const getDay = date => moment(date).format('ddd');

export const todayMoment = moment(new Date()).format('dddd DD MMM, YYYY');

export const todayMomentShort = moment(new Date()).format('DD/MM/YYYY');

export const dayDate = date => moment(date).format('dddd, MMMM DD, YYYY');

export const dayDateAppointment = date =>
  moment(date).format('dddd MMMM DD,YYYY');

export const humanizeFloat = x => {
  return x ? Number(x.toFixed(2).replace(/\.?0*$/, '')) : 0;
};

export const formatAddress = address => {
  const addressDetails = [
    address.addressLine1,
    address.addressLine2,
    address.city,
    address.state
  ]
    .filter(val => val)
    .join(', ');
  let formattedAddress = address.name;
  formattedAddress +=
    (addressDetails || address.zip) && address.name ? ' - ' : '';
  formattedAddress += addressDetails + ' ' + address.zipCode;
  return formattedAddress;
};

export const formatClientAddress = addresses => {
  addresses?.map(address => {
    Object.keys(address).forEach(function(key) {
      if (typeof address[key] === 'string') address[key] = address[key]?.trim();
    });
    return {
      ...address
    };
  });
};

export const convertMinsToTimeFormat = mins =>
  new Date(mins * 1000 * 60).toISOString().substr(11, 5);

export const convertTimeToMins = time => {
  const arr = time.split(':');
  return parseInt(arr[0]) * 60 + parseInt(arr[1]);
};
export const formatClinicAbbreviation = (
  clinic,
  nameField,
  abbreviationField
) => (clinic ? clinic[nameField] + ' (' + clinic[abbreviationField] + ')' : '');

export const formatClinicHubOption = clinic => {
  const clinicLabel = `${clinic.name} (${clinic.abbreviation})`;
  const clinicType = CLINIC_TYPES.find(x => x.id === clinic.clinicType)?.label;
  return {
    label: `${clinicLabel} ${
      clinic.priority > 0 && clinicType ? `[${clinicType}]` : ''
    }`,
    value: clinic.id
  };
};

export const formatProviderDisplayName = provider =>
  `${formatUsernameOriginal(
    provider?.firstName,
    provider?.lastName
  )}, ${provider?.speciality?.abbreviation || ''} (${provider?.clinic
    ?.abbreviation || ''})`;
export const getTotalMin = time =>
  moment.duration(moment(time).format('HH:mm')).asMinutes();
