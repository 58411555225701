import React from 'react';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'lib/ui';
import moment from 'moment';

export const MAIN_CLIENTS_COLUMNS = [
  {
    title: 'Name',
    dataIndex: 'name'
  },
  {
    title: 'DOB',
    dataIndex: 'dob'
  },
  {
    title: 'Gender',
    dataIndex: 'gender'
  },
  {
    title: 'Home Clinic',
    dataIndex: 'homeClinic'
  },
  {
    title: 'Status',
    dataIndex: 'status'
  }
];

export const CLINIC_CLIENTS_COLUMNS = [
  {
    title: 'Name',
    dataIndex: 'name'
  },
  {
    title: 'DOB',
    dataIndex: 'dob'
  },
  {
    title: 'Gender',
    dataIndex: 'gender'
  },
  {
    title: 'Phenotype',
    dataIndex: 'phenoType'
  }
];

export const MAIN_CLIENT_AUTHORIZED_THERAPIES_COLUMNS = handleEdit => [
  {
    title: 'Auth ID',
    dataIndex: 'authGroupID',
    render: text => <strong>{text}</strong>
  },
  {
    title: 'Start',
    dataIndex: 'startDate',
    render: text => <strong>{text}</strong>
  },
  {
    title: 'End',
    dataIndex: 'endDate',
    render: text => <strong>{text}</strong>
  },
  {
    title: 'Report Due',
    dataIndex: 'dueDate',
    render: text => <strong>{text}</strong>
  },
  {
    title: 'Auths Remaining',
    dataIndex: 'authsRemaining',
    render: remaining => (
      <strong className={remaining?.lessThanTwentyPercent ? 'red' : ''}>
        {remaining?.count}
      </strong>
    )
  },
  {
    title: 'Time Remaining',
    dataIndex: 'timeRemaining',
    render: text => <strong>{text}</strong>
  },
  {
    title: 'View/Edit',
    dataIndex: 'view_edit',
    render: (_, record) => (
      <FontAwesomeIcon icon={faEdit} onClick={e => handleEdit(e, record)} />
    )
  }
];

export const MAIN_CLIENT_EXPANDABLE_TABLE_COLUMNS = [
  {
    title: 'Appointment Type',
    dataIndex: 'appointmentType',
    render: text => <strong>{text}</strong>,
    width: '20%'
  },
  {
    title: 'Auths Completed',
    dataIndex: 'completed',
    render: text => <strong>{text}</strong>,
    width: '13%'
  },
  {
    title: 'Auths Scheduled',
    dataIndex: 'scheduled',
    render: text => <strong>{text}</strong>,
    width: '16%'
  },
  {
    title: 'Auths Remaining',
    dataIndex: 'remaining',
    render: remaining => (
      <strong className={remaining?.lessThanTwentyPercent ? 'red' : ''}>
        {remaining?.count}
      </strong>
    ),
    width: '16%'
  },
  {
    title: 'Auths to Fulfill',
    dataIndex: 'toFulfill',
    render: text => <strong>{text}</strong>
  }
];

export const APPOINTMENT_CLIENT_AUTH_THERAPIES_COLUMNS = [
  {
    title: 'Auth ID',
    dataIndex: 'authGroupID',
    render: text => <strong>{text}</strong>
  },
  {
    title: 'Start',
    dataIndex: 'startDate',
    render: text => <strong>{text}</strong>
  },
  {
    title: 'End',
    dataIndex: 'endDate',
    render: text => <strong>{text}</strong>
  },
  {
    title: 'Time Remaining',
    dataIndex: 'timeRemaining',
    render: text => <strong>{text}</strong>
  }
];
export const APPOINTMENT_CLIENT_EXPANDABLE_TABLE_COLUMNS = [
  {
    title: 'Appointment Type',
    dataIndex: 'appointmentType',
    render: text => <strong>{text}</strong>,
    width: '29%'
  },
  {
    title: 'Completed',
    dataIndex: 'completed',
    render: text => <strong>{text}</strong>,
    width: '19%'
  },
  {
    title: 'Scheduled',
    dataIndex: 'scheduled',
    render: text => <strong>{text}</strong>,
    width: '18%'
  },
  {
    title: 'Remaining',
    dataIndex: 'remaining',
    render: remaining => (
      <strong className={remaining?.lessThanTwentyPercent ? 'red' : ''}>
        {remaining?.count}
      </strong>
    ),
    width: '17%'
  },
  {
    title: 'To Fulfill',
    dataIndex: 'toFulfill',
    render: text => <strong>{text}</strong>,
    width: '17%'
  }
];

export const MAIN_CLIENT_SCHEDULING_STATUS_COLUMNS = onClick => [
  {
    title: 'Appt. Type',
    dataIndex: 'apptType',
    width: '20%'
  },
  {
    title: 'Appt. Sub-Type',
    dataIndex: 'apptSubType',
    width: '20%'
  },
  {
    title: 'Ideal Plan',
    dataIndex: 'idealPlan',
    width: '15%'
  },
  {
    title: 'Receiving',
    dataIndex: 'receiving',
    width: '15%'
  },
  {
    title: 'To Fulfill',
    dataIndex: 'toFulfill',
    width: '15%'
  },
  {
    title: 'Action',
    dataIndex: 'action',
    render: (_text, record) => {
      return (
        record.canSchedule && (
          <Button className="secondary" onClick={() => onClick(record)}>
            Schedule
          </Button>
        )
      );
    },
    width: '15%'
  }
];

export const MAIN_CLIENT_RECOMMENDED_EVALUATIONS_COLUMNS = [
  {
    title: 'Appointment Type',
    dataIndex: 'apptType',
    render: text => <strong>{text}</strong>,
    width: '25%'
  },
  {
    title: 'Appointment Sub-Type',
    dataIndex: 'apptSubType',
    render: text => <strong>{text}</strong>,
    width: '25%'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: text => <strong>{text}</strong>,
    width: '25%'
  },
  {
    title: 'Date Created',
    dataIndex: 'createdAt',
    render: text => <strong>{text}</strong>,
    width: '25%'
  }
];

export const MAIN_CLIENT_RECOMMENDED_THERAPIES_COLUMNS = [
  {
    title: 'Appointment Type',
    dataIndex: 'apptType',
    render: text => <strong>{text}</strong>,
    width: '16.5%'
  },
  {
    title: 'Appointment Sub-Type',
    dataIndex: 'apptSubType',
    render: text => <strong>{text}</strong>,
    width: '16.5%'
  },
  {
    title: 'Recommended',
    dataIndex: 'recommendedQuantity',
    render: text => <strong>{text}</strong>,
    width: '16.5%'
  },
  {
    title: 'Requested',
    dataIndex: 'requestedQuantity',
    render: text => <strong>{text}</strong>,
    width: '16.5%'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: text => <strong>{text}</strong>,
    width: '16.5%'
  },
  {
    title: 'Date',
    dataIndex: 'updatedAt',
    render: text => <strong>{text}</strong>,
    width: '16.5%'
  }
];

export const APPOINTMENT_CLIENT_WAITLIST_COLUMNS = [
  {
    title: 'Appt. Type',
    dataIndex: 'appt',
    render: text => <strong>{text}</strong>,
    width: '30%'
  },
  {
    title: 'Appt. Sub Type',
    dataIndex: 'subType',
    render: text => <strong>{text}</strong>,
    width: '30%'
  },
  {
    title: 'To FulFill',
    dataIndex: 'toFulFill',
    render: text => <strong>{text}</strong>,
    width: '22%'
  },
  {
    title: 'Waiting',
    dataIndex: 'waiting',
    render: text => <strong>{text}</strong>,
    width: '18%'
  }
];
