import React, { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { TitleWrapper, ContentWrapper } from './clients.style';
import SearchForm from 'views/components/ui/form/search/index';
import Content from 'views/components/ui/content';
import Table from 'views/containers/table';
import { MAIN_CLIENTS_COLUMNS } from 'utils/constants/tables/clients';
import { useQuery } from '@apollo/react-hooks';
import { GET_CLIENT_LIST } from 'api/graphql/v2/queries/Clients';
import { getClientsData } from 'utils/mappers/response/clients';
import { formatInitial, formatClinicName } from 'utils/format';
import { DEFAULT_PAGE_SIZE, DEFAULT_SORT } from 'utils/constants/default';
import { IClient } from 'model/v2/clients';

const columns = MAIN_CLIENTS_COLUMNS;

const mapClientsData = (clients: IClient[]) =>
  getClientsData(clients).map((client: IClient) => {
    return {
      ...client,
      key: client.id,
      status: client.isActive ? 'Active' : 'Inactive',
      gender: formatInitial(client.profile?.gender),
      homeClinic: formatClinicName({
        name: client.clinic?.name,
        abbreviation: client.clinic?.abbreviation
      })
    };
  });

const Clients: React.FC<{}> = () => {
  const history = useHistory();
  const location = useLocation();

  const searchParams = useMemo(() => new URLSearchParams(location?.search), [
    location
  ]);
  const page = searchParams.get('page') || '1';
  const search = searchParams.get('name') || '';

  const updateHistory = useCallback(() => {
    history.push({
      pathname: '/clients',
      search: searchParams.toString()
    });
  }, [searchParams, history]);

  const onPageChange = useCallback(
    page => {
      searchParams.set('page', page);
      updateHistory();
    },
    [updateHistory, searchParams]
  );

  const onSearchChange = useCallback(
    e => {
      if (!e.target.value) {
        searchParams.delete('name');
        updateHistory();
      }
    },
    [updateHistory, searchParams]
  );

  const onSearch = useCallback(
    value => {
      if (value) {
        searchParams.delete('page');
        searchParams.set('name', value);
        updateHistory();
      }
    },
    [searchParams, updateHistory]
  );

  const { data, loading, error } = useQuery(GET_CLIENT_LIST, {
    variables: {
      search: search ? search.trimEnd() : null,
      from: parseInt(page) * DEFAULT_PAGE_SIZE - DEFAULT_PAGE_SIZE,
      size: DEFAULT_PAGE_SIZE,
      sort: DEFAULT_SORT
    },
    fetchPolicy: 'cache-and-network'
  });

  return (
    <div>
      <TitleWrapper>
        <div className="inner-wrap">
          <h1>Clients</h1>
          <div className="actions">
            <SearchForm
              placeholder="Search by name or email"
              defaultValue={search}
              onChange={onSearchChange}
              onSearch={onSearch}
            />
          </div>
        </div>
      </TitleWrapper>
      <ContentWrapper>
        <div className="inner-wrap">
          <Content loading={loading} error={error} data={data}>
            {({ clientsList }) => (
              <Table
                columns={columns}
                data={mapClientsData(clientsList.clients)}
                total={clientsList.count}
                page={page}
                onPageChangeCallback={onPageChange}
                onRowSelect={(row: IClient) =>
                  history.push('/clients/' + row.id)
                }
              />
            )}
          </Content>
        </div>
      </ContentWrapper>
    </div>
  );
};

export default Clients;
