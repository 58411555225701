import { Drawer } from 'antd';
import styled from 'styled-components';
import FormItem from 'api/sharedComponents/reactHookFormComponents/formItem';
import { FormError } from '../../../api/sharedComponents/reactHookFormComponents/error';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import {
  Dispatch,
  SetStateAction,
  useState,
  useMemo,
  useCallback,
  useEffect,
  useContext
} from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  CLIENT_EDIT_AVAILABILITY_BLOCK,
  WAIT_LIST
} from 'utils/validators/clients';
import { mapToLocationsDownOption } from 'utils/mappers/form';
import ExpandMoreFilled from 'assets/img/ExpandMoreFilled.png';
import ExpandLessFilled from 'assets/img/ExpandLessFilled.png';
import { Checkbox as DayCheckbox } from 'lib/ui/checkbox';
import React from 'react';
import { Checkbox } from 'semantic-ui-react';

import {
  TitleWrapper,
  WaitlistWrapper,
  WaitListFilterWrapper,
  DrawerWrapper,
  DrawerFooterWrapper
} from '../../components/waitlistPage/waitlist.styled';
import EditTimeForm from 'components/AvailabilityTemplateModalForm/EditForm/EditTimeForm';
import { Button } from 'lib/ui';
import ModalBottomWithDelete from 'components/AvailabilityTemplateModalForm/ReusableComponents/ModalBottomWithDelete';
import SelectedLocations from 'pages/MainCalendarPage/AppointmentForm/ProviderFilter/SelectedLocations';
import moment from 'moment';
import {
  PlainTimepicker,
  TimerTextField,
  Datepicker
} from 'api/sharedComponents/reactHookFormComponents';
import { useHistory } from 'react-router';
import { checkIfFilterSelected } from 'utils/mappers/carePlans';
import {
  FilterPageNameEnum,
  getFilterSelections
} from 'components/calendar/calendarToolbar/FilterTagList/form/utils';
import { method, methodOf } from 'lodash';
import SidebarSavedFilter from 'components/calendar/filters/sidebarSavedFilter';
import {
  DELETE_FILTER,
  UPDATE_SIDEBAR_FILTER,
  UPDATE_FILTER
} from 'api/graphql/v2/mutations/Filters';
import { useMutation } from '@apollo/react-hooks';
import { GET_SCHEDULER_FILTERS } from 'api/graphql/v2/queries/Filters';
import { DefaultTagFilterOption } from 'utils/constants/calendarFilters';
import { accountContext } from 'App';
import { waitlistSavedFilterContext } from '../waitlistPage/WaitlistPage';
import { DAYS_FILTER } from 'utils/constants/lists';
const WaitListSideDrawer = (
  {
    refetch,
    setIsShowDrawer,
    isShowDrawer,
    locations,
    setAdditionalFilter,
    additionalFilter,
    location,
    Skeleton,
    waitListDataLoading,
    selectSavedFilter,
    savedFilter,
    handleSearchedValues,
    setHasData,
    setClickedFilter,
    defaultFilter,
    savedFilters,
    isSavedFilterLoading,
    pageName,
    setCheckedFilter,
    checkedFilter,
    clearAllFilters,
    filterSelectionType,
    allFilters
  },
  { ...restProps }
) => {
  const [selectedFilter, setSelectedFilter] = useState('');
  let { searchParams } = restProps;
  const setCheckedSavedFilter = useContext(waitlistSavedFilterContext);
  const days = DAYS_FILTER.slice(1, 6);
  useEffect(() => {
    if (checkedFilter !== '') setCheckedFilter(savedFilter);
  }, [isShowDrawer]);
  const { reset, ...methods } = useForm(
    {
      defaultValues: {
        start: additionalFilter.startTime
          ? moment(additionalFilter.startTime)
          : moment().startOf('day'),
        location: additionalFilter.locations,
        end: additionalFilter.endTime
          ? moment(additionalFilter.endTime)
          : moment().startOf('day'),
        isDefault: DefaultTagFilterOption.no,
        name: '',
        filter: undefined
      },
      resolver: yupResolver(WAIT_LIST)
    },
    [additionalFilter]
  );

  let history = useHistory();

  const locationsOptions = useMemo(() => {
    return mapToLocationsDownOption(locations || []);
  }, [locations]);

  const [selectedDay, setSelectedDay] = useState(additionalFilter.days);
  const [selectedLocation, setSelectedLocation] = useState(
    additionalFilter.locations
  );
  const [removedFilterTags, setRemovedFilterTags] = useState(false);
  const [endTime, setEndTime] = useState(moment().startOf('day'));
  const [startTime, setStartTime] = useState(moment().startOf('day'));
  const [editedFilter, setEditedFilter] = useState('');
  const [deletedFilter, setDeletedFilter] = useState('');
  const [isConfirmed, setIsConfirmaed] = useState(false);
  useEffect(() => {
    setSelectedLocation(additionalFilter.locations);
    methods.setValue('location', additionalFilter.locations);
    setSelectedDay(additionalFilter.days);
    const startTimeVal = additionalFilter.startTime
      ? moment(additionalFilter.startTime, 'HH:mm')
      : moment().startOf('day');
    setStartTime(startTimeVal);
    methods.setValue('start', startTimeVal);
    const endTimeVal = additionalFilter.endTime
      ? moment(additionalFilter.endTime, 'HH:mm')
      : moment().startOf('day');
    setEndTime(endTimeVal);
    methods.setValue('end', endTimeVal);
  }, [additionalFilter]);
  const onHandleCheckChange = (value, index) => {
    if (selectedDay.length === 0) setSelectedDay([index]);
    else if (value.target.checked) setSelectedDay(prev => [...prev, index]);
    else setSelectedDay(prev => prev.filter(data => data !== index));
  };

  const onHandleLocationCheck = useCallback(
    (value, checked) => {
      if (selectedLocation?.length === 0) {
        methods.setValue('location', [value]);
        setSelectedLocation([value]);
      } else if (checked)
        setSelectedLocation(prev => {
          methods.setValue('location', [...prev, value]);
          return [...prev, value];
        });
      else
        setSelectedLocation(prev => {
          let isLocationEmpty = prev.filter(data => data !== value);
          if (isLocationEmpty.length === 0)
            methods.setValue('location', undefined);
          return prev.filter(data => data !== value);
        });

      methods.trigger('location');
    },
    [methods, SelectedLocations, setSelectedLocation]
  );

  const submitForm = useCallback(
    submittedData => {
      if (
        checkIfFilterSelected(
          submittedData.start,
          submittedData.end,
          selectedDay,
          selectedLocation
        )
      ) {
        setCheckedFilter('');
        setAdditionalFilter({
          isFilterApplied: true,
          locations: selectedLocation,
          startTime: submittedData.start,
          endTime: submittedData.end,
          days: selectedDay
        });
        reset();
        setSelectedLocation([]);
        setSelectedDay([]);
        setIsShowDrawer(false);
        searchParams && searchParams.set('page', '1');
        searchParams &&
          history.push({
            pathname: location.pathname,
            search: searchParams.toString()
          });
      }
    },
    [selectedDay, methods]
  );

  const isCheckedLocation = id => {
    return selectedLocation.includes(id);
  };

  const isCheckedDays = id => {
    return selectedDay.includes(id);
  };

  const isLocationChecked = id => {
    return selectedLocation.includes(id);
  };

  const getTime = isStartOrEnd => {
    if (additionalFilter[isStartOrEnd]) {
      return moment(additionalFilter[isStartOrEnd]);
    } else return moment().startOf('day');
  };
  const clearAll = () => {
    setSelectedDay([]);
    setSelectedLocation([]);
    methods.setValue('start', moment().startOf('day'));
    methods.setValue('end', moment().startOf('day'));
    setStartTime(moment().startOf('day'));
    setEndTime(moment().startOf('day'));
    methods.setValue('location', undefined);
    if (additionalFilter.isFilterApplied) setCheckedFilter('');
    setAdditionalFilter({
      isFilterApplied: false,
      locations: [],
      startTime: null,
      endTime: null,
      days: []
    });
  };

  const startDateHandler = React.useCallback(event => {
    const time = event.target.value;
    const momentValue = moment(time, 'HH:mm');
    methods.setValue('dirtyEdit', true);
    setStartTime(momentValue);
    methods.setValue('start', momentValue);
  });

  const endDateHandler = React.useCallback(event => {
    const time = event.target.value;
    const momentValue = moment(time, 'HH:mm');
    methods.setValue('dirtyEdit', true);
    methods.setValue('end', momentValue);
    setEndTime(momentValue);
  });
  const [isAvailabilityExpanded, setIsAvailabilityExpanded] = useState(false);
  const [isSaveedFilterExpanded, setIsSaveedFilterExpanded] = useState(false);
  const [filtername, setFiltername] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const { errors, handleSubmit } = methods;
  const [updateSidebarFilter] = useMutation(UPDATE_SIDEBAR_FILTER);
  const [updateFilter] = useMutation(UPDATE_FILTER);
  const [deleteRecord] = useMutation(DELETE_FILTER);
  const account = useContext(accountContext);
  const onconfirm = useCallback(async () => {
    setErrorMsg('');
    if (deletedFilter !== '') {
      const filter = allFilters?.find(f => f.name === deletedFilter);
      setIsConfirmaed(true);
      try {
        await deleteRecord({
          variables: { id: filter?.id?.toString() },
          refetchQueries: [
            {
              query: GET_SCHEDULER_FILTERS,
              variables: { id: account.username, pageName: pageName }
            }
          ]
        });
        refetch && refetch();
        if (setCheckedFilter && filter.name === checkedFilter) {
          setCheckedFilter('');
        }
        setEditedFilter('');
        setDeletedFilter('');
        setIsConfirmaed(false);
        refetch && refetch();
      } catch (error) {
        console.log('Error while deleting filter', JSON.stringify(error));
      }
    } else {
      const name = methods.getValues('name');
      const isDefault = methods.getValues('isDefault');
      const filter = methods.getValues('filter');
      setFiltername(name);
      if (name === '') {
        setErrorMsg('Name is required');
        return;
      } else if (filter.name !== name) {
        if (
          allFilters.filter(
            v => v.name?.toLowerCase().trim() === name?.toLowerCase().trim()
          ).length > 0
        ) {
          setErrorMsg('This name already exists');
          return;
        }
      }
      if (filter?.id && name !== '') {
        setIsConfirmaed(true);
        if (removedFilterTags) {
          const filterSelections = getFilterSelections(
            filter,
            filterSelectionType
          );
          const updatedFilter = {
            name: name,
            userId: account.username,
            isDefault: isDefault === DefaultTagFilterOption.yes,
            filterSelections: filterSelections,
            pageName: pageName
          };
          //Edit mode
          try {
            await updateSidebarFilter({
              variables: {
                id: filter?.id?.toString(),
                pageName: pageName,
                updatedFilter: updatedFilter
              },
              refetchQueries: [
                {
                  query: GET_SCHEDULER_FILTERS,
                  variables: { id: account.username, pageName: pageName }
                }
              ]
            });
            refetch && refetch();
            if (
              isDefault === DefaultTagFilterOption.yes &&
              checkedFilter !== name
            ) {
              if (setCheckedFilter) setCheckedFilter(name);
              if (selectSavedFilter) selectSavedFilter(name);
              if (setCheckedSavedFilter) setCheckedSavedFilter(name);
            }
            setEditedFilter('');
            setDeletedFilter('');
            setIsConfirmaed(false);
            setRemovedFilterTags(false);
          } catch (error) {
            console.log('Error while updating filter', JSON.stringify(error));
          }
        } else {
          //Edit mode
          try {
            await updateFilter({
              variables: {
                id: filter?.id?.toString(),
                pageName: pageName,
                updatedFilter: {
                  name: name,
                  isDefault: isDefault === DefaultTagFilterOption.yes,
                  userId: account.username
                }
              },
              refetchQueries: [
                {
                  query: GET_SCHEDULER_FILTERS,
                  variables: { id: account.username, pageName: pageName }
                }
              ]
            });
            refetch && refetch();
            if (
              isDefault === DefaultTagFilterOption.yes &&
              checkedFilter !== name
            ) {
              if (setCheckedFilter) setCheckedFilter(name);
              if (selectSavedFilter) selectSavedFilter(name);
              if (setCheckedSavedFilter) setCheckedSavedFilter(name);
            }
            setEditedFilter('');
            setDeletedFilter('');
            setIsConfirmaed(false);
          } catch (error) {
            console.log('Error while updating filter', JSON.stringify(error));
          }
        }
      }
    }
  }, [
    deletedFilter,
    updateFilter,
    updateSidebarFilter,
    filterSelectionType,
    removedFilterTags,
    selectSavedFilter,
    setRemovedFilterTags
  ]);
  return (
    <>
      {isShowDrawer && (
        <FormProvider {...methods}>
          <Drawer
            //titleStyle={{ fontSize: '28px', fontWeight: 500 }}
            bodyStyle={{ paddingRight: '0px', paddingLeft: '0px' }}
            title=""
            placement="right"
            closable={true}
            onClose={() => {
              setIsShowDrawer(false);
            }}
            visible={isShowDrawer}
            maskClosable={true}
            width={360}
          >
            <DrawerWrapper>
              <div className="AddtnlFilter_Avail_header">
                <p>Filters</p>
              </div>
              <div className="Avail_Container">
                <div className="AddtnlFilter_Avail AddtnlFilter_Avail_first">
                  <div className="AddtnlFilter_Title">
                    <p>Availability</p>
                  </div>
                  <div
                    onClick={() => {
                      setIsSaveedFilterExpanded(false);
                      setEditedFilter('');
                      setDeletedFilter('');
                      setIsAvailabilityExpanded(!isAvailabilityExpanded);
                    }}
                    className="cursor"
                  >
                    <img
                      src={
                        isAvailabilityExpanded
                          ? ExpandLessFilled
                          : ExpandMoreFilled
                      }
                      alt="Expand Icon"
                    />
                  </div>
                </div>
                {isAvailabilityExpanded && (
                  <>
                    <div className="Addtnl_Form_Content">
                      <p>Days</p>
                      {days?.map(location => (
                        <div className="saved_filters" key={location.value}>
                          <DayCheckbox
                            className="saved-filters_checkbox"
                            label={location.text}
                            name={location.text}
                            value={location.value}
                            checked={isCheckedDays(location.value)}
                            onChange={event => {
                              onHandleCheckChange(event, location.value);
                            }}
                          >
                            {location.text}
                          </DayCheckbox>
                        </div>
                      ))}
                    </div>
                    <div className="Addtnl_Form_Content">
                      <p>Times</p>
                      <div className="time-formItem">
                        <FormItem optional={false} label="Start Time">
                          <Controller
                            name="start"
                            control={methods.control}
                            render={fieldProps => (
                              <TimerTextField
                                className="time-style"
                                field={fieldProps}
                                errors={methods.errors}
                                value={startTime?.format('HH:mm')}
                                onChange={startDateHandler}
                                InputLabelProps={{
                                  shrink: true
                                }}
                                inputProps={{
                                  step: 900
                                }}
                              />
                            )}
                          />
                        </FormItem>
                        <FormItem optional={false} label="End Time">
                          <Controller
                            name="end"
                            control={methods.control}
                            render={fieldProps => (
                              <TimerTextField
                                className="time-style"
                                field={fieldProps}
                                errors={methods.errors}
                                value={endTime?.format('HH:mm')}
                                onChange={endDateHandler}
                                InputLabelProps={{
                                  shrink: true
                                }}
                                inputProps={{
                                  step: 900
                                }}
                              />
                            )}
                          />
                        </FormItem>
                      </div>
                    </div>
                    <div className="Addtnl_Form_Content location_label">
                      {waitListDataLoading ? (
                        <>
                          <div>location</div>
                          <Skeleton active={true} />
                        </>
                      ) : (
                        <FormItem label="Locations">
                          <Controller
                            control={methods.control}
                            name={'location'}
                            render={(fieldProps, index) => (
                              <div key={index}>
                                {locationsOptions?.map((
                                  location,
                                  index // Adding index as the key
                                ) => (
                                  <div
                                    className="saved_filters"
                                    key={location.id + '-' + index}
                                  >
                                    <DayCheckbox
                                      className="saved-filters_checkbox"
                                      label={location.label}
                                      name={location.label}
                                      value={location.value}
                                      checked={isCheckedLocation(
                                        location.value
                                      )}
                                      onChange={event => {
                                        onHandleLocationCheck(
                                          location.value,
                                          event.target.checked
                                        );
                                      }}
                                    >
                                      {location.label}
                                    </DayCheckbox>
                                  </div>
                                ))}
                              </div>
                            )}
                          />
                          <div>
                            <FormError
                              name={'location'}
                              errors={methods.errors}
                            />
                          </div>
                        </FormItem>
                      )}
                    </div>
                  </>
                )}
                <div className="AddtnlFilter_Avail">
                  <div className="AddtnlFilter_Title">
                    <p>Saved Filters</p>
                  </div>
                  <div
                    onClick={() => {
                      setIsAvailabilityExpanded(false);
                      if (isSaveedFilterExpanded) {
                        setEditedFilter('');
                        setDeletedFilter('');
                      }
                      setIsSaveedFilterExpanded(!isSaveedFilterExpanded);
                    }}
                    className="cursor"
                  >
                    <img
                      src={
                        isSaveedFilterExpanded
                          ? ExpandLessFilled
                          : ExpandMoreFilled
                      }
                      alt="Expand Icon"
                    />
                  </div>
                </div>
                {isSaveedFilterExpanded && (
                  <>
                    <div className="Addtnl_Form_Content location_label filter_cls">
                      {isSavedFilterLoading ? (
                        <>
                          {/* <div>Saved Filters</div> */}
                          <Skeleton active={true} />
                        </>
                      ) : (
                        <SidebarSavedFilter
                          filters={savedFilters}
                          defaultFilter={defaultFilter}
                          handleSearchedValues={handleSearchedValues}
                          setHasData={setHasData}
                          pageName={pageName}
                          setEditedFilter={setEditedFilter}
                          editedFilter={editedFilter}
                          isConfirmed={isConfirmed}
                          filtername={filtername}
                          setFiltername={setFiltername}
                          setDeletedFilter={setDeletedFilter}
                          deletedFilter={deletedFilter}
                          setCheckedFilter={setCheckedFilter}
                          checkedFilter={checkedFilter}
                          setRemovedFilterTags={setRemovedFilterTags}
                          errorMsg={errorMsg}
                          setErrorMsg={setErrorMsg}
                          setSelectedFilter={setSelectedFilter}
                          selectedFilter={selectedFilter}
                          allFilters={allFilters}
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
            </DrawerWrapper>
            <DrawerFooterWrapper>
              {editedFilter !== '' ? (
                <>
                  <div className="waitlist-sidebar-footer">
                    <div className="Btn_container">
                      <Button
                        className="clear-btn"
                        disabled={isConfirmed}
                        onClick={() => {
                          setEditedFilter('');
                          setDeletedFilter('');
                        }}
                        type="button" // Ensure this is a button type, not submit
                      >
                        Cancel
                      </Button>
                      <Button
                        className="confirm-btn"
                        onClick={onconfirm}
                        disabled={isConfirmed}
                        type="button" // Ensure this is a button type, not submit
                      >
                        Confirm
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                (isAvailabilityExpanded ||
                  (isSaveedFilterExpanded && savedFilters?.length > 0)) && (
                  <>
                    <div className="waitlist-sidebar-footer">
                      <div className="Btn_container">
                        <Button
                          className="clear-btn"
                          onClick={() => {
                            if (additionalFilter.isFilterApplied)
                              setCheckedFilter('');
                            setSelectedFilter('');
                            clearAll();
                            if (isSaveedFilterExpanded) {
                              setCheckedFilter('');
                              clearAllFilters();
                            }
                          }}
                          type="button" // Ensure this is a button type, not submit
                        >
                          Clear All
                        </Button>
                        <Button
                          className="confirm-btn"
                          onClick={() => {
                            if (isSaveedFilterExpanded) {
                              if (
                                checkedFilter !== '' ||
                                selectedFilter !== ''
                              ) {
                                if (checkedFilter !== '') {
                                  selectSavedFilter(checkedFilter);
                                } else {
                                  selectSavedFilter(selectedFilter);
                                  setCheckedFilter(selectedFilter);
                                }
                                setIsShowDrawer(false);
                              }
                            } else {
                              methods.handleSubmit(submitForm)();
                            }
                          }}
                          type="button" // Ensure this is a button type, not submit
                        >
                          Apply Filter
                        </Button>
                      </div>
                    </div>
                  </>
                )
              )}
            </DrawerFooterWrapper>
          </Drawer>
        </FormProvider>
      )}
    </>
  );
};
export default React.memo(WaitListSideDrawer);
